var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"New order","bold":""}}),_c('div',{staticClass:"ml-auto"},[_c('SButtonCreateOrder',{attrs:{"color":"primary","options":{ disabled: _vm.disabled }},on:{"click":_vm.create}})],1)],1),_c('CCardBody',[_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"mb-3",attrs:{"col":"12","lg":"6"}},[_c('SSelectSupplier',{staticClass:"my-3",attrs:{"label":"Supplier"},on:{"update:value":function($event){_vm.supplier_id = $event},"change":function($event){_vm.supplier = $event}}}),(_vm.supplier)?_c('TTableAsForm',{staticClass:"border-primary rounded p-2 px-3",attrs:{"data":_vm.supplier,"fields":_vm.supplierFields,"splitLeft":5},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value}})]}},{key:"address",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value}})]}},{key:"email",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value}})]}},{key:"note",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value}})]}}],null,false,4058860772)}):_vm._e()],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12","lg":"6"}},[_c('SSelectCurrency',{staticClass:"my-3",attrs:{"label":"Currency","value":_vm.currency_id,"prepend":[]},on:{"update:value":function($event){_vm.currency_id=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }